import { useEffect } from "react";
import { ErrorCode } from "../enums/ErrorCode";
import { AppData } from "../data/AppData";
import {useNavigate} from "react-router-dom";

export function useInvocationCheck() {
  let navigate = useNavigate();
  const invocationIsValid = AppData.useState((s) => s.invocationIsValid);

  useEffect(() => {
    if (!invocationIsValid) {
      AppData.update((s) => {
        s.errorCode = ErrorCode.InvalidInvocation;
      });
      navigate("/", { replace: true });
    }
  }, []);
}
