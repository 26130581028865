import useVH from "react-viewport-height";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorCode } from "../../enums/ErrorCode";
import { AppData } from "../../data/AppData";
import StartComponent from "./StartComponent";
//import { useDataReset } from "../../hooks/useDataReset";
import OrientationLock from "../../components/layouts/screenOrientation/OrientationLock";

function Start() {

    const browserIsSupported = AppData.useState((s) => s.browserIsSupported);
    const deviceIsSupported = AppData.useState((s) => s.deviceIsSupported);

    let navigate = useNavigate();

    //useDataReset();

    useEffect(() => {
        if (!browserIsSupported) {
            setErrorCodeAndRedirect(ErrorCode.UnsupportedBrowser);
            return;
        }

        if (!deviceIsSupported) {
            setErrorCodeAndRedirect(ErrorCode.UnsupportedDevice);
            return;
        }
    }, [browserIsSupported, deviceIsSupported]);

    const setErrorCodeAndRedirect = (code: ErrorCode) => {
        AppData.update((s) => {
            s.errorCode = code;
        });
        navigate("/notice");
        //setRedirect("/notice");
    };

    /*if (redirect) {
        return <Redirect to={redirect} />;
    }*/

    return (
        <OrientationLock>
            <StartComponent />
        </OrientationLock>
    );
}

export default Start;

