import { PrismaSDK } from "@soulmade/prismaid";

class SDKSingleton {
  private static instance: SDKSingleton;
  sdk: PrismaSDK;

  constructor() {
    if (SDKSingleton.instance) {
      throw new Error("Error - use SDKSingleton.getInstance()");
    }
    this.sdk = new PrismaSDK(
      process.env.REACT_APP_SDK_API_KEY!,
      // FIXME: remove dev-url
      "https://api-dev.soulmade-origins.net/prismaid"
    );
    this.sdk.setTwoFingerHoldingMode(false);
    // FIXME: set Languange if wanted
    // i18n.changeLanguage(this.sdk.getFinalSDKLanguage());
  }

  static getInstance(): SDKSingleton {
    SDKSingleton.instance = SDKSingleton.instance || new SDKSingleton();
    return SDKSingleton.instance;
  }
}

export default SDKSingleton;
