import {AnimatePresence} from "framer-motion";
import {Routes, Route, useLocation} from "react-router-dom";

import Failure from "./pages/Failure";
import Start from  "./pages/Start";
import Success from "./pages/Success";
import Fluffy from "./pages/Fluffy";
import Swipe from "./pages/Swipe";
import Open from "./pages/Open";
import WithInvocationCheck from "./components/layouts/WithInvocationCheck";

const RouterComponent = () => {
    const location = useLocation();

    return (
        <div className="touch-pan-y">
            <AnimatePresence exitBeforeEnter>
                <WithInvocationCheck path={location.pathname}>
                    <Routes location={location} key={location.pathname}>
                        <Route path="/" element={<Open/>} />
                        <Route path="/start" element={<Start/>} />
                        <Route path="/swipe" element={<Swipe/>} />
                        <Route path="/success" element={<Success/>}/>
                        <Route path="/fluffy" element={<Fluffy/>}/>
                        <Route path="/failure" element={<Failure/>}/>
                    </Routes>
                </WithInvocationCheck>
            </AnimatePresence>
        </div>
    );
};

export default RouterComponent;
