import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppData } from "../../data/AppData";

interface Props {
    path: string;
    children: ReactNode;
}

const WithInvocationCheck = (props: Props) => {
    let navigate = useNavigate();

    const invocationIsValid = AppData.useState((s) => s.invocationIsValid);

    useEffect(() => {
        const validStartPaths = ["/"];

        if (invocationIsValid) {
            // is valid
            return;
        }
        console.log('invocation check: ' + invocationIsValid + ", path: "+ props.path);

        if (!validStartPaths.includes(props.path)) {
            // is NOT valid, and not a valid start path
            navigate("/", { replace: true });
            console.log("invalid path detected");
        }
    }, [props.path]);

    return <>{props.children}</>;
};

export default WithInvocationCheck;
