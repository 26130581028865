import OrientationLock from "../../components/layouts/screenOrientation/OrientationLock";
import Pink from "./Pink";
import Nude from "./Nude";
import {SwipeData} from "../../data/SwipeData";
import {FluffyData} from "../../data/FluffyData";
import Green from "./Green";
import {Helmet} from "react-helmet-async";
import React from "react";

function Fluffy() {

    const scaleFactor = SwipeData.useState((s) => s.scaleFactor);
    const fluffyName = FluffyData.useState((s) => s.fluffyName);

    let fluffyRoute;

    if (fluffyName === "nude") {
        fluffyRoute =  <Nude scaleFactor={scaleFactor} fluffyName={fluffyName} />;
    }else if (fluffyName === "pink") {
        fluffyRoute =  <Pink scaleFactor={scaleFactor} fluffyName={fluffyName} />;
    }else if (fluffyName === "green") {
        fluffyRoute =  <Green scaleFactor={scaleFactor} fluffyName={fluffyName} />;
    } else {
        //fluffyRoute =  <Pink scaleFactor={scaleFactor} fluffyName={fluffyName} />;
        //navigate("/start");
    }

    return (
        <OrientationLock>
                <div>
                    <Helmet>
                        <meta
                            name="viewport"
                            content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
                        />

                        <meta name="format-detection" content="telephone=no" />
                        <meta name="msapplication-tap-highlight" content="no" />

                        <meta name="apple-mobile-web-app-capable" content="yes" />
                        <meta name="apple-mobile-web-app-title" content="eDLC" />
                        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
                    </Helmet>
                    <div className="overflow-hidden touch-none">
                            {fluffyRoute}
                    </div>
                </div>
        </OrientationLock>
    );
}

export default Fluffy;

