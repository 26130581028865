import useVH from "react-viewport-height";

const Failure = () => {
    useVH();

    return (
        <div>
            <h1>Failure</h1>
        </div>
    );
};

export default Failure;
