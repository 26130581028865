import useVH from "react-viewport-height";

const Success = () => {
    useVH();

    return (
        <div>
            <h1>Start</h1>
        </div>
    );
};

export default Success;
