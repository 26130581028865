import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { OrientationChangeProvider } from "./components/layouts/screenOrientation/OrientationChangeProvider"
import './index.css';
import RouterComponent from './RouterComponent';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <HelmetProvider>
          <OrientationChangeProvider>
              <Router>
                  <RouterComponent />
              </Router>
          </OrientationChangeProvider>
      </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
