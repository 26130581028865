import React from "react";
import classNames from "classnames";
import { CSSProperties, useEffect, useState } from "react";
import { SwipeData } from "../../data/SwipeData";
import {motion} from "framer-motion";

type Props = {
    src: string;
    id: string;
    alt: string;
    originalWidth?: number;
    originalHeight?: number;
    className?: string;
    opacity?: number;
    style?: CSSProperties;
};

function ScaledMotionImage({
                         src,
                         id,
                         alt,
                         originalWidth,
                         originalHeight,
                         className = "",
                         opacity = 1,
                         style = {},
                     }: Props) {
    const scaleFactor = SwipeData.useState((s) => s.scaleFactor);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        let img = new Image();
        img.src = src;
        if(originalWidth){
            setWidth(originalWidth);
        }else{
            img.onload = () => {
                setWidth(img.width);
            };
        }
    }, [src]);

    return (
        <motion.img
            src={src}
            id={id}
            alt={alt}
            className={classNames("transform select-none max-w-none", className)}
            style={{
                width: width * scaleFactor,
                opacity: opacity,
                ...style,
            }}
        />
    );
}

export default ScaledMotionImage;
