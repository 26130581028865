import React from "react";
import {SwipeData} from "../../data/SwipeData";
import {motion, useMotionValue, useTransform} from "framer-motion";

function ProgressBar() {
    const progressValue = useMotionValue(SwipeData.useState((s) => s.progress));
    const progress = SwipeData.useState((s) => s.progress);
    const opacity_shadow = useTransform(progressValue, [0, 100], [0.3, 0.75]);
    return (
        <motion.div
            className="absolute bottom-0 bg-green-500"
            style={{
                width: "100%",
                touchAction: "none",
                pointerEvents: "none",
                borderRadius: "10px 10px 0 0",
                opacity: opacity_shadow
            }}
            initial={{height: 0}}
            animate={{height: `${progress > 5 ? progress : 0}%`}}
            transition={{
                duration: 0.2,
                type: "reverse",
            }}
        ></motion.div>
    );
}

export default ProgressBar;
