import logo from "../../assets/img/01_start/logo_prismade.png";
import useVH from "react-viewport-height";
import startButton from "../../assets/img/01_start/start_icon-play.png";
import startForParents from "../../assets/img/01_start/start_icon-for-parents.png";
import startTutorial from "../../assets/img/01_start/start_icon-tutorial.png";
import click from "../../assets/sounds/click.mp3";
import Sound from "../../components/sounds/Sound";
import {useNavigate} from "react-router-dom";
import {motion} from "framer-motion";
import "../../index.css"
import bg01 from "../../assets/img/01_start/space-background.png";

function StartComponent() {
    const vh = useVH();
    const clickSound = new Sound(click);

    const navigate = useNavigate();

    function handleParentsEvent(e: React.MouseEvent<HTMLDivElement>) {
        e.preventDefault();
        clickSound.play();
        setTimeout(() => {
            navigate("/parents");
        }, 200);
    }
    function handleTutorialEvent(e: React.MouseEvent<HTMLDivElement>) {
        e.preventDefault();
        clickSound.play();
        setTimeout(() => {
            navigate("/tutorial");
        }, 200);
    }
    function handleStartEvent(e: React.MouseEvent<HTMLDivElement>) {
        e.preventDefault();
        clickSound.play();
        setTimeout(() => {
            navigate("/swipe");
        }, 200);
    }

    return (
        <div
            className="flex flex-col items-center justify-between w-screen pb-5 text-center overflow-hidden whitespace-pre-wrap"
            style={{
                height: `${100 * vh}px`,
                width: "100%",
        }}
        >
            <div className="mt-8">
                <img src={logo} alt="" className="w-2/3 md:w-40 lg:w-40 m-auto"/>
            </div>
            <div>
                <motion.div
                    id="tutorial button"
                >
                    <motion.img
                        whileTap={{scale: 0.8}}
                        src={startTutorial}
                        alt="start"
                        className={`w-2/5 md:w-2/5 lg:w-2/5 m-auto`}
                    />
                </motion.div>

                <motion.div
                    id="start button"
                >
                    <motion.img
                        whileTap={{scale: 0.8}}
                        onClick={(e) => handleStartEvent(e)}
                        src={startButton}
                        alt="start"
                        className={`w-2/5 md:w-2/5 lg:w-2/5 m-auto`}
                    />
                </motion.div>
            </div>

            <div className="justify-end flex flex-col">
                <motion.div
                >
                    <motion.img
                        whileTap={{scale: 0.8}}
                        src={startForParents}
                        className={`w-2/5 sm:w-2/5 md:w-2/5 m-auto`}
                    />
                </motion.div>
                <p className="font-light text-white font-mono">Impressum</p>

            </div>
        </div>
    );
}

export default StartComponent;

